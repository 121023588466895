import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  activeRoute = "";

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activeRoute = this.router.url;
      }
    });
    if (this.activeRoute === "") {
      this.activeRoute = this.router.url;
      console.log("this.activeRoute: ", this.activeRoute);
    }
  }
  ngOnInit() {}

  getBanner(name) {
    if (name === "/new") {
      return "../../assets/newMachines/HCW100_MillingMachine.jpg";
    } else if (name === "/maintenance") {
      return "../../assets/maintenance/panel.jpg";
    } else if (name == "/retrofit") {
      return "../../assets/maintenance/panel.jpg";
    } else {
      return "../../assets/slider/1.jpg";
    }
  }

  getQuote(name) {
    if (name === "/new") {
      return `"If you need a machine and don't buy it, then you will ultimately find that you have paid for it and don't have it."
      ― Henry Ford`;
    } else if (name === "/maintenance") {
      return "Fieldservice , repairs and service of machine tools. Certified Siemens partner.";
    } else if (name == "/retrofit") {
      return "Bring your machine tool up to the latest technology standard.";
    } else if(name == "/spare-parts"){
      return 'We stock a wide range of Siemens , Sinumerik , Simodrive 611 and Sinamics S120 spares. We stock Flow waterjet spares parts.'
    } else if(name == "/motor-repairs"){
      return 'We are a certified Siemens Servo Motor repair workshop.'}
    else {
      return "";
    }
  }

  getActiveRoute(){
    return this.activeRoute
  }
}
