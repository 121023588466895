import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'text/html'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) {
   }

  newMail(mail) {
    console.log(mail);
    return this.http.post('https://us-central1-eje-255407.cloudfunctions.net/mail', mail, httpOptions);
  }
}

