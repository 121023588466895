import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { BlogService } from '../blog.service';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
interface Post {
  id: string,
  title: string;
  description: string;
  image: any;
}
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  activePost: Post;
  modalOptions: NgbModalOptions;
  closeResult: string;
  @ViewChild('openModal') openModal: TemplateRef<ElementRef>;
  constructor(private modalService: NgbModal, private dataService: DataService, private blogService: BlogService) { 
    this.modalOptions = {
      size: "lg",
      backdropClass: 'customBackdrop',
  };
  }
  blogposts: any;
  async ngOnInit() {
    this.activePost = {
      id: '',
      title: '',
      description: '',
      image: null
    };
    this.blogposts = await this.blogService.getBlog();
  }
  
  openPost(post) {
    console.log(this.activePost);
    
    this.activePost.id = post.id;
    this.activePost.title = post.title;
    this.activePost.description = post.description;
    this.activePost.image = post.image;
    this.open(this.openModal);
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  
  cancel() {
    this.modalService.dismissAll();
  }
}
