import { BrochuresComponent } from "./brochures/brochures.component";
import { BlogComponent } from "./blog/blog.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { NewMachinesComponent } from "./new-machines/new-machines.component";
import { HomeComponent } from "./home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import { AdminComponent } from "./admin/admin.component";
import { AuthGuard } from "./core/auth.guard";
import { RetrofitComponent } from "./retrofit/retrofit.component";
import { SparePartsComponent } from "./spare-parts/spare-parts.component";
import { MotorRepairsComponent } from "./motor-repairs/motor-repairs.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "new", component: NewMachinesComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "brochures", component: BrochuresComponent },
  { path: "blog", component: BlogComponent },
  { path: "auth", component: AuthComponent },
  { path: "retrofit", component: RetrofitComponent },
  { path: "spare-parts", component: SparePartsComponent },
  { path: "motor-repairs", component: MotorRepairsComponent },
  { path: "admin", component: AdminComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
