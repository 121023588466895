import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { map, take, tap } from "rxjs/operators";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {
    return this.auth.afAuth.authState.pipe(
      take(1),
      map((authState) => !!authState),
      tap(authenticated => {
        if (!authenticated) 
          this.router.navigate(['/auth'])
        else{
          console.log(this.auth.afAuth.auth.currentUser.email);
          if(this.auth.afAuth.auth.currentUser.displayName == null){
            this.auth.afAuth.auth.currentUser.updateProfile({displayName: this.auth.afAuth.auth.currentUser.email.split('@')[0]});
          }
          console.log(this.auth.afAuth.auth.currentUser.displayName);
        }
      })
    )
  }
}