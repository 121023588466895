import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {
  constructor() {}
  @ViewChild("youtubePlayer") player: ElementRef;
  @ViewChild("modal") modal: ElementRef;
  tips = [
    "Field service of machine tools",
    "Specialist in Siemens Sinumerik Controls",
    "Yearly service on machine tools",
    "Leveling of machine tools",
    "Main spindle repairs, including Bearing Test and Draw Bar test",
  ];
  tipsData = [
    {
      title: "Field service of machine tools",
      images: [],
      video: "",
    },
    {
      title: "Specialist in Siemens Sinumerik Controls",
      img: [],
      video: "",
    },
    {
      title: "Yearly service on machine tools",
      images: [],
      video: "",
    },
    {
      title: "Leveling of machine tools",
      images: [],
      video: "",
    },
    {
      title: "Main spindle repairs, including Bearing Test and Draw Bar test",
      images: [
        "../../assets/maintenance/clamprite.jpg",
        "../../assets/maintenance/drawbar.jpg",
      ],
      video: "",
    },
    {
      title: "Thermal check of electrical panels",
      images: [],
      video: "../../assets/maintenance/video1.MOV",
    },
    {
      title: "Renishaw Ball bar test",
      images: [],
      video: "../../assets/maintenance/video2.mov",
    },
    {
      title: "Renishaw laser calibration",
      images: [],
      video: "",
    },
    {
      title: "Add renishaw logo above the previous two points",
      images: [],
      video: "",
    },
  ];
  // selectedVi deo = {url:'https://www.youtube.com/embed/wMxGkB8DW9w?rel=0'};

  services = [
    {
      name: "New Panels Build",
      collection: [
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/1.jpg",
          videoURL: "",
        },
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/2.jpg",
          videoURL: "",
        },
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/3.jpg",
          videoURL: "",
        },
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/4.jpg",
          videoURL: "",
        },
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/5.jpg",
          videoURL: "",
        },
        {
          title: "",
          image: "../../assets/maintenance/panelBuild/6.jpg",
          videoURL: "",
        },
      ],
      description:
        "Electrical rewiring and new control systems for better performance",
      type: "RetroFit",
    },
    {
      name: "Renishaw Ballbar Test",
      collection: [
        {
          title: "Renishaw Ballbar Test 1",
          image:
            "../../assets/maintenance/renishawBallTest/renishaw_ballbar_test_1.png",
          videoURL: "https://www.youtube.com/embed/wMxGkB8DW9w?rel=0",
        },
        {
          title: "Renishaw Ballbar Test 2",
          image:
            "../../assets/maintenance/renishawBallTest/renishaw_ballbar_test_2.png",
          videoURL: "https://www.youtube.com/embed/qvFt-lzRyxg?rel=0",
        },
      ],
      description:
        "Electrical rewiring and new control systems for better performance",
      type: "Machine Geometry Check",
    },
  ];
  setSelectedVideo(video) {
    this.player.nativeElement.src = video;
  }
  ngOnInit() {}
}
