import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spare-parts',
  templateUrl: './spare-parts.component.html',
  styleUrls: ['./spare-parts.component.scss']
})
export class SparePartsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
