import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { map } from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "EJEIE";
  showHeader = false;
  showFooter = true;
  constructor(private router: Router, private ngxService: NgxUiLoaderService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (
          this.router.url === "/brochures" ||
          this.router.url === "/maintenance" ||
          this.router.url === "/new" ||
          this.router.url == "/retrofit" ||
          this.router.url == "/motor-repairs" ||
          this.router.url == "/spare-parts"
        ) {
          this.showHeader = true;
        } else {
          this.showHeader = false;
        }
        if (this.router.url === "/auth" || this.router.url === "/admin") {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      }
    });
  }
  ngOnInit(): void {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 1000);
  }
}
