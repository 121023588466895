import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motor-repairs',
  templateUrl: './motor-repairs.component.html',
  styleUrls: ['./motor-repairs.component.scss']
})
export class MotorRepairsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
