
import { Component, OnInit, Inject } from '@angular/core';
import { ContactService } from '../contact.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  nameSurname: string;
  email: string;
  subject: string;
  message: string;
  formSubmitted = false;
  formFailed = false;
  public contactForm: FormGroup;
  sitekey = '6Lc-yr4UAAAAAMTes4nDWP0aZmdaeN5-ACP5ZpC3';
  constructor(private mailService: ContactService, private formbuilder: FormBuilder, @Inject(DOCUMENT) private document: Document) { }
  
  emailAddress = 'sales@ejeie.co.za';
  ngOnInit() {
    this.contactForm = this.formbuilder.group({
      nameSurname: new FormControl(this.nameSurname, [Validators.required, Validators.minLength(5)]),
      email: new FormControl(this.email, [Validators.required, Validators.email]),
      subject: new FormControl(this.subject, [Validators.required]),
      message: new FormControl(this.message),
      recaptcha: ['', Validators.required]
    });
  }

  onSubmit() {
    const name = this.contactForm.value.nameSurname;
    const email = this.contactForm.value.email;
    const message = this.contactForm.value.message;
    const subject = this.contactForm.value.subject;
    // this.mail(name, email, subject, message);
    this.mailFallback();
  }

  handleReset() {

  }
  handleExpire() {

  }
  handleLoad() {

  }
  handleSuccess() {

  }

  mail(name: string, email: string, subject: string, message: string) {
    const sendList = [];
    sendList.push('sales@ejeie.co.za');
    const msg = {
      to: ['contact@dewetsmit.co.za'],
      cc: ['webcontact@ejeie.co.za'],
      from: email,
      subject: 'EJE - Website Contact',
      text: 'Visitor Name: ' + name + ', Visitor Email: ' + email + ' Subject: ' + subject + ', Message: ' + message + '.',
      html: '<p>Visitor Name: ' + name + ',</p><br/><p>Visitor Email: ' + email + ',</p><br/><p>Subject: ' + subject + ',</p><br/><p>Message: ' + message + '</p>',
    };

    // console.log(msg);
    this.mailService.newMail(msg)
    .subscribe(res => {
      // console.log(res);
      this.formSubmitted = true;
    }, err => {
      console.log('error: ', err);
      this.formSubmitted = true;
      console.log(err.status);
      if (err.status === 200) {
        this.formSubmitted = true;
      } else {
        this.formFailed = true;
        this.formSubmitted = false;
      }
    });
  }

  mailFallback() {
    const msg = {
      to:  'sales@ejeie.co.za',
      subject: 'Website Contact'};
    const mailString = 'mailto:'+ msg.to + "?subject=" + msg.subject + "&body=" + this.contactForm.value.message;
    this.document.location.href = mailString;
    // this.formFailed = false;
    // this.formSubmitted = true;
  }
}


