import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { map, finalize } from 'rxjs/operators';
import * as firebase from 'firebase';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})


export class BlogService {

blogpath = 'https://www.googleapis.com/blogger/v3/blogs/174613808658483030/posts?key=AIzaSyCLD_HcU-J-yOi_5RADf7BCfBanYxL2afM';
// ejebloggerOauth = '536638361237-o8ges13bu4prm0c3rijmuk6l908ucoqd.apps.googleusercontent.com';
storageRef = firebase.storage().ref();
task: AngularFireUploadTask;
percentage: Observable<number>;
snapshot: Observable<any>;
downloadURL: Observable<any>;

  constructor(private http: HttpClient, private af: AngularFirestore, private storage: AngularFireStorage, private ngxLoader: NgxUiLoaderService) { }

  getBlog(): Observable<object> {
    const blog = this.af.collection('blogposts');
    const posts = blog.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return posts;
  }

async createPost(data, file) {
  this.ngxLoader.start();
  const url = await this.startUpload({}, file);
  return new Promise<any>((resolve, reject) => {
        this.af.collection('blogposts')
            .add(data)
            .then(async res => {
              this.af.collection('blogposts').doc(res.id).update({
                image: url
            })
            .then(function() {
                console.log('Document successfully written!');
            })
            .catch(function(error) {
                console.error('Error writing document: ', error);
            });
              this.ngxLoader.stop();
            }, err => reject(err));
    });
}

async update(post, data, file) {
  this.ngxLoader.start();
  const url = await this.startUpload({}, file);
  return this.af.collection('blogposts').doc(post.id).update({
            title: data.title,
            description: data.description,
            image: url
        })
        .then(() => {
          this.ngxLoader.stop();
          return 'success';
        })
        .catch((error) => {
          this.ngxLoader.stop();
          return error;
        });
}



async startUpload(doc, image = null) {
if (!image) {
  return '';
} else {
  // The storage path

  const path = `${image.name}`;

  // Reference to storage bucket
  const ref = this.storage.ref(path);

  // The main task
  this.task = this.storage.upload(path, image);

  // Progress monitoring
  this.percentage = await this.task.percentageChanges();
  this.percentage.subscribe((res) => {
    if (res === 100){
      console.log(res);
    }
  });
  this.task.snapshotChanges().pipe(
    finalize(() => this.downloadURL = ref.getDownloadURL())
  )
  return this.downloadURL;

  }
}

async delete(post) {
  this.ngxLoader.start();
  return this.af.collection('blogposts').doc(post.id).delete().then(() => {
    this.ngxLoader.stop();
    return 'success';
  }).catch((error) => {
    this.ngxLoader.stop();
    return error;
  }); ;
}
}
