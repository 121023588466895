import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from './auth.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { NewMachinesComponent } from './new-machines/new-machines.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { BrochuresComponent } from './brochures/brochures.component';
import { BlogComponent } from './blog/blog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ModalModule } from 'ngx-modal';
import { AdminComponent } from './admin/admin.component';
import { AuthComponent } from './auth/auth.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AuthGuard} from './core/auth.guard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { RetrofitComponent } from './retrofit/retrofit.component';
import { SparePartsComponent } from './spare-parts/spare-parts.component';
import { MotorRepairsComponent } from './motor-repairs/motor-repairs.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    NewMachinesComponent,
    MaintenanceComponent,
    BrochuresComponent,
    BlogComponent,
    FooterComponent,
    HeaderComponent,
    ContactComponent,
    AdminComponent,
    AuthComponent,
    RetrofitComponent,
    SparePartsComponent,
    MotorRepairsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    AngularFontAwesomeModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    ModalModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [AuthService, AuthGuard, AngularFireStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }
