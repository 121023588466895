import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-brochures',
  templateUrl: './brochures.component.html',
  styleUrls: ['./brochures.component.scss']
})
export class BrochuresComponent implements OnInit {
  partners = [];
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.partners = this.dataService.partners;
  }

}
