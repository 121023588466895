import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataService {
  partners = [
    {
      name: "siemens",
      isPartner: true,
      newMachines: false,
      link: "https://www.siemens.com/global/en/products/automation/systems/cnc-sinumerik/automation-systems.html",
      img: "../../assets/partners/siemens.png",
      bgImg: "",
      color: "",
      bgColor: "",
    },
    {
      name: "hearn",
      isPartner: true,
      newMachines: false,
      link: "https://www.wdhearn.co.za/products",
      img: "../../assets/partners/hearn.png",
      bgImg: "",
      color: "",
      bgColor: "",
    },
    {
      name: "bvs",
      isPartner: true,
      newMachines: false,
      link: "https://bvs-electronics.com/de/",
      img: "../../assets/partners/bvs-v2.jpg",
      bgImg: "",
      color: "",
      bgColor: "",
    },
    {
      name: "flow",
      isPartner: true,
      newMachines: true,
      link: "https://www.flowwaterjet.com/",
      img: "../../assets/partners/flow.png",
      bgImg: "../../assets/newMachines/flow.svg",
      color: "#00929F",
      bgColor: "rgba(0, 146, 159, 0.88)",
    },
    {
      name: "skoda",
      isPartner: true,
      newMachines: false,
      link: "https://www.cz-smt.cz/en/",
      img: "../../assets/partners/skoda_new.jpg",
      bgImg: "",
      color: "",
      bgColor: "",
    },
    {
      name: "mmd",
      isPartner: true,
      newMachines: false,
      link: "https://mmdwm.de/",
      img: "../../assets/partners/mmd.png",
      bgImg: "",
      color: "",
      bgColor: "",
    },
    {
      name: "fanuc",
      isPartner: false,
      newMachines: true,
      link: "https://www.fanuc.co.za/",
      img: "../../assets/partners/fanuc.png",
      bgImg: "../../assets/newMachines/fanuc_bg.jpg",
      color: "#BF803E",
      bgColor: "rgba(191, 128, 42, 0.88)",
    },
    {
      name: "leadwell",
      isPartner: false,
      newMachines: true,
      link: "https://www.wdhearn.co.za/brand/leadwell",
      img: "../../assets/partners/leadwell.png",
      bgImg: "../../assets/newMachines/leadwell_bg.svg",
      color: "#264880",
      bgColor: "rgba(38, 73, 128, 0.88)",
    },
  ];
  constructor() {}
}
