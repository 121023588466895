import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-new-machines',
  templateUrl: './new-machines.component.html',
  styleUrls: ['./new-machines.component.scss']
})
export class NewMachinesComponent implements OnInit {
  partners = [];
  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    this.partners = this.dataService.partners;
  }

}
