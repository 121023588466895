import { DomSanitizer } from '@angular/platform-browser';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { User } from 'firebase';
import { AuthService } from './../auth.service';
import { Component, OnInit, HostListener, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { BlogService } from '../blog.service';
import { post } from 'selenium-webdriver/http';

interface currentUser {
  displayName: string;
}

interface Post {
  id: string,
  title: string;
  description: string;
  image: any;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  modalOptions: NgbModalOptions;
  activePost: Post;

  @ViewChild('deleteModal') deleteModal: TemplateRef<ElementRef>;
  @ViewChild('editModal') editModal: TemplateRef<ElementRef>;
  constructor(private sanitizer:DomSanitizer, private authService: AuthService, private blogService: BlogService, private fb: FormBuilder,  private modalService: NgbModal) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
  };
}
  postForm: FormGroup;
  currentUser: currentUser;
  blogposts: any;

  file: File | null = null;
  selectedFiles: FileList;
  // @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
  //   const file = event && event.item(0);
  //   console.log("okay file change host listens");
  //   this.file = file;
  //   if(this.file){
  //     this.postForm.patchValue({
  //       image: this.file.name
  //    });
  //   }

  // }

  ngOnInit() {
    this.currentUser = this.authService.user;
    this.activePost = {
      id: '',
      title: '',
      description: '',
      image: null
    };
    this.blogposts = this.blogService.getBlog();
    this.postForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      image: ['', [Validators.required]],
    });
  }

  signOut() {
    this.currentUser = null;
    this.authService.signOut();
  }

  async detectFiles(event) {
    this.selectedFiles = event.target.files;
    this.file = await this.selectedFiles.item(0);
    if (this.file) {
          this.activePost.image = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
          this.postForm.patchValue({
            image: this.file.name
         });
        }
}

async createPost() {
  console.log(this.file);
  
  await this.blogService.createPost(this.postForm.value, this.file);
  this.postForm.reset();
}

deletePost(post) {
  this.activePost.id = post.id;
  this.activePost.title = post.title;
  this.activePost.description = post.description;
  this.activePost.image = post.image;
  this.postForm.patchValue({
    title: post.title,
    description: post.description,
    image: post.image
 });
  this.open(this.deleteModal);
}

editPost(post) {
  this.activePost.id = post.id;
this.activePost.title = post.title;
this.activePost.description = post.description;
this.activePost.image = post.image;

this.postForm.patchValue({
    title: post.title,
    description: post.description,
    image: post.image
 });

this.open(this.editModal);
}

async update() {
  await this.blogService.update(this.activePost, this.postForm.value, this.file);
  this.postForm.reset();
  this.modalService.dismissAll();
}

async delete() {
  this.blogService.delete(this.activePost);
  this.postForm.reset();
  this.modalService.dismissAll();
}

open(content) {
  this.modalService.open(content, this.modalOptions).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return  `with: ${reason}`;
  }
}

cancel() {
  this.postForm.reset();
  this.modalService.dismissAll();
}

}
