import { Injectable } from '@angular/core';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
user: User;


constructor(public  afAuth: AngularFireAuth, public  router: Router,private ngxService: NgxUiLoaderService) {
  this.afAuth.authState.subscribe(user => {
    if (user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      localStorage.setItem('user', null);
    }
  });
}

async  login(email:  string, password:  string) {

  try {
    this.ngxService.start();
      await  this.afAuth.auth.signInWithEmailAndPassword(email, password)
      this.router.navigate(['admin']);
    this.ngxService.stop();
  } catch (e) {
      alert("Error!"  +  e.message);
  }
  }

  async signOut(){
    this.ngxService.start();
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['auth']);
    this.ngxService.stop();
  }

  get authenticated(): boolean {
    return this.afAuth.authState !== null;
  }

  get currentUserObservable(): any {
    return this.afAuth.auth;
  }

}
