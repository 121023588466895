import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  images = ['../../assets/slider/1.jpg', '../../assets/slider/2.jpg', '../../assets/slider/3.jpg', '../../assets/slider/4.jpg'];

  partners = [];
  constructor(public dataService: DataService) { }

  ngOnInit() {
    this.partners = this.dataService.partners;
  }

}
