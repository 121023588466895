import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private  authService: AuthService, private fb: FormBuilder) { }
  userForm: FormGroup;


  ngOnInit(): void {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.authService.login(this.userForm.controls.email.value, this.userForm.controls.password.value);
    }

}
